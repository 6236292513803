#progress-container {
  width: 100%;
  height: 1px;
  position: -webkit-sticky;
  position: sticky;
  background: rgb(255, 255, 255);
  top: 0;
  z-index: 2;
}

#progress-bar {
  height: 6px;
  background: #d9534f;
  width: 100%;
}
