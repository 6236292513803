/* ------------------- Text ------------------- */

h1,h2,h3 {
  font-family: 'Merriweather', serif;
  color: #2a2f36;
}

.subtitle {
  color: #abb7b7;
}
.regular-text {
  font-weight: 400;
  font-size: 20px;
}

.paragraph{
  text-indent: 0px;
  margin-bottom: 10px;
  text-justify:distribute-all-lines;
}

.bold-text{
  font-weight: 600;
}

.italic-text{
  font-style:italic;
  font-weight: 550;
}

.body-text {
  color: #6c7a89;
}

.section {
  margin-bottom: 175px;
}

.section-title {
  width: 100%;
  margin: auto auto;
  margin-bottom: 50px;
}

a{
  color: #abb7b7 !important;
  text-decoration: none;
}
a:hover {
  text-decoration: none !important;
  color: #d9534f !important;
}

.sup{
  vertical-align: super;
  font-size: 0.75em;
}

/* -------------------      ------------------- */

.section-header-img-div {
  width: 100%;
  height: 550px;
  background-position: center;
  background-size: cover;
  box-shadow: rgba(25, 28, 31, 0.08) 0px 14px 32px,
    rgba(25, 28, 31, 0.04) 0px 8px 16px, rgba(25, 28, 31, 0.04) 0px -1px 0px;
}

.about-me-section-header-img {
  background-image: url('../assets/JoaoHenriqueLuz.jpg');
  background-position: 50% 0;
  float: right;
  width:50%;
  height:300px;
  background-size: cover;
  margin: 0px 20px;
  border-radius: 5px;
}

.resume-section-header-img {
  background-image: url('https://images.pexels.com/photos/5088022/pexels-photo-5088022.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260');
}

.not-found-page {
  width: 100%;
  height: 75%;
  margin: auto auto;
  background-image: url('https://images.pexels.com/photos/691637/pexels-photo-691637.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260');
  background-position: center;
  background-size: cover;
  box-shadow: rgba(25, 28, 31, 0.08) 0px 14px 32px,
    rgba(25, 28, 31, 0.04) 0px 8px 16px, rgba(25, 28, 31, 0.04) 0px -1px 0px;
}

.project-card-title {
  font-family: 'Merriweather', serif;
  color: white;
}
.project-card {
  position: relative;
  text-align: center;
  width: 70%;
  height: 380px;
  margin: 35px auto;
  border-radius: 5px;
  color: white;
}
.project-card-img {
  object-fit:cover;
  width: 100%;
  height: 400px;
  max-height: 400px;
  margin: auto auto;
  box-shadow: rgba(25, 28, 31, 0.08) 0px 14px 32px,
    rgba(25, 28, 31, 0.04) 0px 8px 16px, rgba(25, 28, 31, 0.04) 0px -1px 0px;
    border-radius: 5px;
}

.project-card-text {
  display: none;
}

.project-card-img {
  filter: brightness(30%);
}

.hovered-project-card{
  filter: brightness(30%);
}

.project-card-img ~ .project-card-text {
  display: block;
}

.project-card-text ~ .project-card-img{
  filter: brightness(30%);
} 

.offscreen {
  position: absolute;
  left: -999em;
}

.centered-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.contact-form {
  width: 100%;
  margin: auto;
  padding: 30px 50px;
  background-color: #f5f7fa;
}
.contact-form form {
  width: 100%;
  margin: auto auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.contact-form label {
  font-size: 18px;
  color: #6c7a89;
  font-weight: 500;
}

.contact-form-item {
  width: 100%;
  margin: auto auto;
}

.contact-input {
  width: 100%;
  border: solid #6c7a8936;
  border-radius: 4px;
  font-size: 20px;
  padding: 15px 18px;
  display: block;
}

.custom-button {
  display: inline-block;
  width: fit-content;
  margin-top: 30px;
  color: white;
  font-size: 20px;
  padding: 10px;
  text-align: center;
  border: solid #6c7a8936;
  border-radius: 4px;
  background-color: rgba(18, 103, 160, 0.774);
}

.custom-button:hover {
  color: rgba(18, 103, 160, 0.774);
  background-color: white;
}

.custom-button:disabled {
  color: #abb7b7;
  background-color: white;
}

.custom-alert {
  position: fixed;
  bottom: calc(5vh);
  right: 30px;
  height: 100px;
  width: 25%;
  overflow: hidden;
}

button::-moz-focus-inner {
  border: 0;
}

#root {
  margin: 0 auto;
}

.notion-container{
  width: 100vw - 400px;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-content: center;
}

.notion-container img{
  width: 100%;
  /* object-fit: cover; */
}

.notion-container figure.notion-asset-wrapper{
  margin: auto;
  width: 50% !important;
}

.notion-container figure div {
  padding-bottom: 10% !important;
}

/* On screens that are less than 400px, display the bar vertically, instead of horizontally */
@media screen and (max-width: 768px) {
  .about-me-section-header-img{
    background-image: url('../assets/JoaoHenriqueLuz.jpg');
    background-position: 50% 0;
    float: none;
    width:100%;
    height:300px;
    background-size: cover;
    margin: 0px 0px 15px;
    border-radius: 5px;
  }

}
@media screen and (max-width: 1040px) {
  .section-header-img-div {
    width: 100%;
    height: 45vh;
    background-position: center;
    background-size: cover;
    box-shadow: rgba(25, 28, 31, 0.08) 0px 14px 32px,
      rgba(25, 28, 31, 0.04) 0px 8px 16px, rgba(25, 28, 31, 0.04) 0px -1px 0px;
  }

  img {
    width: 100%;
    max-height: 45vh;
    object-fit: cover;
  }
}

@media screen and (max-width: 1200px) {
  .project-card {
    position: relative;
    text-align: center;
    width: 100%;
    height: 380px;
    margin: 35px auto;
    border-radius: 5px;
  }
}
