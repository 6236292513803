.sidebar {
  margin: 0;
  padding: 0;
  width: 400px;
  background-color: #f1f1f1;
  position: fixed;
  height: 100%;
  overflow: auto;
  background-image: url('../assets/stars.jpeg');
  background-position: center;
  background-size: cover;
}

.sidebar-column {
  width: 75%;
  margin: auto auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
}

.nav-link {
  display: block;
  color: #abb7b7;
  padding: 5px;
  text-decoration: none;
}

.nav-link.active {
  color: white;
}

.nav-link:hover:not(.active) {
  color: white;
}

.nav-link:hover {
  cursor: pointer;
}


.nav-link-icon {
  flex: 15%;
}

.nav-link-text {
  flex: 85%;
  margin-left: 10px;
}

.sidebar-subtitle {
  color: #e7f7f7;
  font-weight: 400;
}

.page-content {
  margin-left: 400px;
  padding: 0;
  height: 100%;
  -webkit-overflow-scrolling:touch;
}

.page-content-children{
  width: 90%;
  margin: 30px auto 0 auto;
  padding: 20px 0px;
}

/* Tooltip container */
.has-tooltip {
  position: relative;
  display: inline-block;
}
.has-fixed-tooltip {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.has-tooltip .tooltip-text, .has-fixed-tooltip .tooltip-text {
  visibility: hidden;
  width: 120px;
  background-color: rgba(0, 0, 0, 0.74);
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -60px;
}

.has-fixed-tooltip .tooltip-text {
  visibility: hidden;
  width: 120px;
  background-color: #d9534f;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 3;
  bottom: 100%;
  left: 50%;
  margin-left: -60px;
}

/* Show the tooltip text when you mouse over the tooltip container */
.has-tooltip:hover .tooltip-text {
  visibility: visible;
}

.has-fixed-tooltip .tooltip-text {
  visibility: visible;
}

/* On screens that are less than 700px wide, make the sidebar into a topbar */
@media screen and (max-width: 1200px) {
  .sidebar {
    width: 100%;
    height: auto;
    position: relative;
    padding-top: 30px;
  }
  .nav-link {
    float: left;
    font-weight: 550;
  }
  
  .page-content {
    padding: 0px;
    margin-left: 0;
    width: 100%;
  }
}

/* On screens that are less than 400px, display the bar vertically, instead of horizontally */
@media screen and (max-width: 800px) {
  .page-content-children {
    width: 90%;
  }
}

/* On screens that are less than 400px, display the bar vertically, instead of horizontally */
@media screen and (max-width: 680px) {
  .nav-link {
    float: none;
  }
}
