.resume-timeline-div {
  min-height: 100%;
}
.resume-timeline {
  background-color: #2d84b9;
  min-height: 100%;
  width: 5px;
  margin: 0 auto;
}

.dot {
  height: 20px;
  width: 20px;
  background-color: white;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  transform: translate(-37%, -10%);
  border: solid 2px #2d84b9;
}
.date {
  height: 20px;
  width: 45px;
  margin-left: 10px;
  background-color: white;
  display: flex;
  flex-direction: row;
  position: relative;
  transform: translate(-150%, -130%);
  justify-content: center;
  align-items: center;
  padding: 2px auto;
}

.folder-header {
  font-variant-numeric: tabular-nums;
  font-weight: 550;
  padding: 20px;
  background-color: #f5f7fa;
}

.folder-header span {
  padding-right: 10px;
  padding-left: 10px;
  background-color: #f5f7fa;
}

.folder-body {
  padding: 10px 40px;
}

.folder-header span:hover {
  cursor: pointer;
}

.folder-open {
  border: solid 3px #2d84b9;
  border-right: 0;
  border-top: 0;
  border-bottom: 0;
}

.folder-section {
  display: flex;
  flex-direction: row;
}

.icon-button {
  background: none;
  padding: 0;
  border: none;
  outline: none;
}

.icon-button:focus {
  outline: none;
}

@media only screen and (max-width: 768px) {
  .date {
    transform: translate(-140%, -130%);
  }
}

@media only screen and (max-width: 575px) {
  .folder-header {
    padding-left: 5px;
    background-color: #f5f7fa;
  }
  .folder-open {
    border: solid 3px #2d84b9;
    border-right: 0;
    border-top: 0;
    border-bottom: 0;
    padding-left: 5px;
  }
}
